<template>
  <div class="card supply-card">
    <div class="card-header border-0">
      <div class="row supply-rule">
        <div class="supply-rule-text">
          <h3 class="mb-0">
            {{ $t("COMMON.UPCOMING_PURCHASE") }}
          </h3>
        </div>
        <div class="supply-rule-filter">
          <base-input>
            <flat-picker
              :placeholder="$t('SUPPLY_RULES.SUPPLY_RULE_ORDER_DATE')"
              :config="{
                allowInput: true,
                enableTime: false,
                type: 'date',
                minDate: 'today',
                locale: $flatPickrLocale(),
                disable: [
                  function (date) {
                    return date.getDay() === 6 || date.getDay() === 0;
                  },
                ],
              }"
              class="form-control datepicker"
              v-model="order_date"
              @on-change="onOrderDateChange()"
            >
            </flat-picker>
          </base-input>

          <base-input>
            <flat-picker
              :placeholder="$t('SUPPLY_RULES.SUPPLY_RULE_DELIVERY_DATE')"
              :config="{
                allowInput: true,
                enableTime: false,
                type: 'date',
                minDate: 'today',
                locale: $flatPickrLocale(),
                disable: [
                  function (date) {
                    return date.getDay() === 6 || date.getDay() === 0;
                  },
                ],
              }"
              class="form-control datepicker"
              v-model="delivery_date"
              @on-change="onDeliveryDateChange()"
            >
            </flat-picker>
          </base-input>
        </div>
        <div class="supply-rule-button">
          <base-button
            type="primary"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SUPPLY_RULES)"
            @click="viewSupplyRuleList"
          >
            <span class="btn-inner--text">
              {{ $t("COMMON.SEE_MORE") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <el-table
        class="table-responsive table"
        :data="supplyRules"
        header-row-class-name="thead-light"
      >
        <div slot="empty" v-if="loading">
          <img src="/img/loading.gif" style="height: 100px; width: 100px" />
        </div>
        <el-table-column :label="$t('COMMON.SUPPLIERS')" min-width="130px">
          <template v-slot="{ row }">
            [{{ row?.id }}]({{ row?.order_before_hour }})
            <span class="text-danger">
              {{ displayDiffDay(row) }}
            </span>
            <br />
            {{ row?.supplier?.company_name }} | {{ row.order_delay }} <br />
            <template v-if="row?.supplier?.category">
              {{ row.delivery_days }}
              <br />
              <small>
                {{ row?.supplier?.category ?? "-" }}
              </small>
            </template>
            <br />
            {{ displayHour(row) ? row?.order_before_hour : "" }}
          </template>
        </el-table-column>

        <el-table-column
          min-width="130px"
          :label="$t('SUPPLY_RULES.SUPPLY_RULE_ORDER_DATE')"
        >
          <template v-slot="{ row }">
            <div v-if="!!row?.order_max_date">
              <template v-if="!row?.is_today">
                <div v-if="isFutureTime(row?.order_before_hour)" class="">
                  {{ $formatDate(row?.order_max_date, "ddd D MMM YYYY", true) }}
                  {{ displayHour(row) ? row?.order_before_hour : "" }}
                </div>
                <div v-else class="">
                  <span
                    class=""
                    v-if="isSameDateWithToday(row?.order_max_date)"
                  >
                    {{
                      $formatDate(row?.order_max_date, "ddd D MMM YYYY", true)
                    }}
                    {{ displayHour(row) ? row?.order_before_hour : "" }}
                  </span>
                  <span v-else>
                    {{
                      $formatDate(row?.order_max_date, "ddd D MMM YYYY", true)
                    }}
                    {{ displayHour(row) ? row?.order_before_hour : "" }}
                  </span>
                </div>
              </template>
              <template v-else>
                <span v-if="isFutureTime(row?.order_before_hour)">
                  {{ $formatDate(row?.order_max_date, "ddd D MMM YYYY", true) }}
                  {{ row?.order_before_hour }}
                </span>
                <small v-else class="text-warning">
                  {{ $t("SUPPLY_RULES.DELIVERY_TIME_EXCEEDED") }}
                </small>
              </template>
            </div>
            <div v-else>
              <div v-if="order_date">
                {{ $formatDate(order_date, "ddd D MMM YYYY", true) }}
                {{ displayHour(row) ? row?.order_before_hour : "" }}
              </div>
              <small v-else class="text-warning">
                <span
                  v-if="
                    !hasNotDeliveryDate(
                      row?.delivery_date ?? delivery_date,
                      row
                    )
                  "
                >
                  {{ $t("SUPPLY_RULES.DELIVERY_TIME_EXCEEDED") }}
                </span>
              </small>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          min-width="130px"
          :label="$t('SUPPLY_RULES.SUPPLY_RULE_DELIVERY_DATES')"
        >
          <template v-slot="{ row }">
            <div v-if="row?.delivery_date">
              {{ $formatDate(row?.delivery_date, "ddd D MMM YYYY", true) }}
              <span
                v-if="!row?.order_max_date && calculateDaysDifference(row)"
                class="text-warning"
              >
                <br />
                <template
                  v-if="
                    hasNotDeliveryDate(row?.delivery_date ?? delivery_date, row)
                  "
                >
                  {{ $t("SUPPLY_RULES.DELIVERY_AVAILABLE_ONLY") }}: <br />
                  <span v-for="(day, key) in row.delivery_days" :key="key">
                    {{ $t(`SUPPLY_RULES.SUPPLY_RULE_DELIVERY_DAY_${day}`)
                    }}<i v-if="row.delivery_days.length - 1 > key">,</i>
                  </span>
                </template>
              </span>
            </div>
            <div v-else>
              <div v-if="delivery_date">
                {{ $formatDate(delivery_date, "ddd D MMM YYYY", true) }}
                <span
                  v-if="!row?.order_max_date && calculateDaysDifference(row)"
                  class="text-warning"
                >
                  <br />
                  <template
                    v-if="
                      hasNotDeliveryDate(
                        row?.delivery_date ?? delivery_date,
                        row
                      )
                    "
                  >
                    {{ $t("SUPPLY_RULES.DELIVERY_AVAILABLE_ONLY") }}: <br />
                    <span v-for="(day, key) in row.delivery_days" :key="key">
                      {{ $t(`SUPPLY_RULES.SUPPLY_RULE_DELIVERY_DAY_${day}`)
                      }}<i v-if="row.delivery_days.length - 1 > key">,</i>
                    </span>
                  </template>
                </span>
              </div>
              <strong class="text-warning" v-else>
                {{ $t("SUPPLY_RULES.SUPPLY_RULE_INVALID_ORDER_DATE") }}
              </strong>
            </div>
          </template>
        </el-table-column>

        <el-table-column :label="$t('COMMON.WAREHOUSES')" min-width="130px">
          <template v-slot="{ row }">
            {{ row?.warehouse?.name }}
          </template>
        </el-table-column>

        <el-table-column width="80px" align="center">
          <div slot-scope="{ row }" class="table-actions">
            <el-tooltip :content="$t('COMMON.VIEW')" placement="top">
              <a
                type="text"
                @click="viewSupplyRule(row)"
                class="table-action"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fas fa-eye"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

export default {
  name: "supply-rule-widget-table",

  components: {
    flatPicker,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,

    [TableColumn.name]: TableColumn,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    isWidget: {
      type: Boolean,
      default: false,
      description: "Check if the component will be used as widget",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      supplyRules: [],
      loading: true,
      selectedOrganization: null,
      delivery_days: null,
      order_date: this.getValidateDate(),
      delivery_date: null,
      selectedLocations: null,
      selectedSuppliers: null,
      selectedProducts: null,
      selectedWarehouses: [],

      tableData: [
        {
          page: "/argon/",
          visitors: "4,569",
          unique: "340",
          bounceRate: "46,53%",
          bounceRateDirection: "up",
        },
        {
          page: "/argon/index.html",
          visitors: "3,985",
          unique: "319",
          bounceRate: "46,53%",
          bounceRateDirection: "down",
        },
        {
          page: "/argon/charts.html",
          visitors: "3,513",
          unique: "294",
          bounceRate: "36,49%",
          bounceRateDirection: "down",
        },
        {
          page: "/argon/tables.html",
          visitors: "2,050",
          unique: "147",
          bounceRate: "50,87%",
          bounceRateDirection: "up",
        },
        {
          page: "/argon/profile.html",
          visitors: "1,795",
          unique: "190",
          bounceRate: "46,53%",
          bounceRateDirection: "down",
        },
      ],
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterWarehouseableType: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocations: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedSuppliers: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedProducts: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedWarehouses: {
      handler: "getListDebounced",
      immediate: true,
    },
    order_date: {
      handler: "getListDebounced",
      immediate: true,
    },
    delivery_date: {
      handler: "getListDebounced",
      immediate: true,
    },
  },
  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    onOrderDateChange() {
      this.order_date = this.getValidateDate(this.order_date);
      this.delivery_date = null;
    },

    onDeliveryDateChange() {
      this.order_date = null;
      this.delivery_date = this.getValidateDate(this.delivery_date);
    },

    getValidateDate(targetDate = moment()) {
      const currentDate = moment(targetDate);
      if (currentDate.day() === 6) {
        currentDate.add(2, "days");
      } else if (currentDate.day() === 0) {
        currentDate.add(1, "days");
      }
      while (currentDate.day() === 0 || currentDate.day() === 6) {
        currentDate.add(1, "days");
      }

      return currentDate.format("YYYY-MM-DD");
    },

    isFutureTime(timeString) {
      const currentTime = moment();
      const timeToCheck = moment(timeString, "HH:mm");
      return currentTime.isBefore(timeToCheck);
    },

    isSameDateWithToday(dateString) {
      const currentDate = moment();
      const dateToCheck = moment(dateString, "YYYY-MM-DD");
      return currentDate.isSame(dateToCheck, "day");
    },

    isFutureDate(dateString) {
      const currentDate = moment();
      const dateToCheck = moment(dateString, "YYYY-MM-DD");
      return currentDate.isBefore(dateToCheck, "day");
    },

    calculateNextOrderDate(supplyRule) {},

    displayHour(supplyRule) {
      const momentDate1 = moment(supplyRule.order_max_date);
      const momentDate2 = moment(supplyRule.delivery_date);
      if (this.isSameDateWithToday(supplyRule.order_max_date)) {
        /*if (!this.isFutureTime(supplyRule.order_before_hour)) {
          return false;
        }
        if (
          this.displayDiffDay(supplyRule) >= parseInt(supplyRule?.order_delay && supplyRule?.is_today)
        ) {
          return false;
        }*/
      }
      if (
        this.isFutureDate(supplyRule.order_max_date) &&
        supplyRule?.order_max_dates?.length
      ) {
        return true;
      }
      if (momentDate1.day() === 5) {
        return true;
      }
      if (supplyRule.order_delay > 6) {
        return true;
      }
      //return true;
      return this.diffWeekdays(supplyRule) <= parseInt(supplyRule.order_delay);
    },

    displayDiffDay(supplyRule) {
      const momentDate1 = moment(supplyRule.order_max_date);
      const momentDate2 = moment(supplyRule.delivery_date);
      return momentDate2.diff(momentDate1, "days");
    },

    diffWeekdays(supplyRule) {
      const startDate = moment(supplyRule.order_max_date);
      const endDate = moment(supplyRule.delivery_date);
      let weekdays = 0;

      let currentDate = startDate.clone();
      while (currentDate.isSameOrBefore(endDate)) {
        // Vérifier si le jour actuel est un jour de semaine (lundi à vendredi)
        if (currentDate.day() !== 0 && currentDate.day() !== 6) {
          weekdays++;
        }
        // Passer au jour suivant
        currentDate.add(1, "day");
      }

      return weekdays;
    },

    hasNotDeliveryDate(targetDate, row) {
      targetDate = moment(targetDate);
      return !row?.delivery_days?.includes(targetDate.day().toString());
    },

    loadOrderDates(supplyRule) {
      const deliveryDate = moment(supplyRule.delivery_date);
      const deliveryDateCopy = moment(deliveryDate);
      let targetDate = deliveryDateCopy
        .clone()
        .subtract(supplyRule.order_delay, "days");

      if (targetDate.day() === 0) {
        targetDate.subtract(2, "days");
      }

      if (targetDate.day() === 6) {
        targetDate.subtract(1, "days");
      }

      supplyRule.order_before_hour = moment(
        supplyRule.order_before_hour,
        "HH:mm:ss"
      ).format("HH:mm");

      return {
        ...supplyRule,
        order_max_date: targetDate,
      };
    },

    async getList() {
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include:
            "organization,allowedLocations,products,suppliers,warehouses",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }
        if (this.selectedLocations) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              allowedLocations: this.selectedLocations.map((loc) => loc.id),
            },
          };
        }
        if (this.selectedSuppliers) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              suppliers: this.selectedSuppliers.map((supplier) => supplier.id),
            },
          };
        }
        if (this.selectedProducts) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              products: this.selectedProducts.map((product) => product.id),
            },
          };
        }
        if (this.selectedWarehouses) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              warehouses: this.selectedWarehouses.map(
                (warehouse) => warehouse.id
              ),
            },
          };
        }
        const currentTime = moment();
        if (!!this.order_date) {
          const order_date =
            this.order_date + " " + currentTime.format("HH:mm:ss");
          params = {
            ...params,
            filter: {
              ...params.filter,
              order_date, //: this.order_date,
            },
          };
        }
        if (!!this.delivery_date) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              delivery_date:
                this.delivery_date + " " + currentTime.format("HH:mm:ss"),
            },
          };
        }
        if (this.delivery_date || this.order_date) {
          this.loading = true;
          await this.$store.dispatch("supplyRules/widgetList", params);
          this.supplyRules = this.$store.getters["supplyRules/widgetList"];
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },

    viewSupplyRule(supplyRule) {
      this.$router.push({
        name: "View Supply Rule",
        params: { id: supplyRule.id },
      });
    },

    viewSupplyRuleList() {
      this.$router.push({ name: "List Supply Rules" });
    },

    calculateDaysDifference(supplyRule) {
      const end = moment().add(supplyRule.order_delay, "days");
      const start = moment();
      const startDeliveryDate = moment();
      const endDeliveryDate = moment(supplyRule.delivery_date);
      if (Math.abs(end.diff(start, "days")) <= 1) {
        /*const currentTime = moment();
        const enteredTime = moment(supplyRule.order_before_hour, 'HH:mm');
        const isFuture = enteredTime.isAfter(currentTime);*/
        return !supplyRule?.delivery_days?.includes(
          endDeliveryDate?.day()?.toString()
        );
      }
      return (
        Math.abs(end.diff(start, "days")) <=
        Math.abs(endDeliveryDate.diff(startDeliveryDate, "days"))
      );
    },
  },
};
</script>
